import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Input from '../components/Input';
import { Select } from '../components/Select';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Security';
import Update from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { getUsers, newPasswordUser } from '../actions/userActions';
import { NEW_PASSWORD_USER_RESET } from '../constants/userConstants';
import { Delete } from '@material-ui/icons';
import {
  deleteMessage,
  getMessages,
} from '../actions/messageActions';
import { DELETE_MESSAGE_RESET } from '../constants/messageConstants';
import {
  deleteQuery,
  getQueries,
  updateQuery,
} from '../actions/queryActions';
import {
  DELETE_QUERY_RESET,
  UPDATE_QUERY_RESET,
} from '../constants/queryConstants';
import {
  getFeedbacks,
  submitFeedbackResponse,
} from '../actions/feedbackActions';
import { SUBMIT_RESPONSE_FEEDBACK_RESET } from '../constants/feedbackConstants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

const MatTable = ({ headCells, data, type }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [userId, setUserId] = useState('');
  const [passwordModal, setPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [arr, setArr] = useState([]);

  const openModal = (id) => {
    setModal(true);
    setUserId(id);
  };

  const generateNewPasswordHandler = () => {
    setModal(false);
    dispatch(newPasswordUser(userId));
  };

  const newPasswordUserInfo = useSelector(
    (state) => state.newPasswordUserInfo
  );
  const {
    loadingNewPasswordUser,
    newPasswordUserData,
    errorNewPasswordUser,
  } = newPasswordUserInfo;

  useEffect(() => {
    dispatch({ type: NEW_PASSWORD_USER_RESET });
    if (newPasswordUserData) {
      setPassword(newPasswordUserData);
      setPasswordModal(true);
      toast('Password changed successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
    } else if (errorNewPasswordUser) {
      toast(errorNewPasswordUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [newPasswordUserData, errorNewPasswordUser]);

  const closePasswordModal = () => {
    setPasswordModal(false);
    setPassword('');
    setTimeout(() => {
      dispatch(getUsers());
    }, 1000);
  };

  const deleteMessageInfo = useSelector(
    (state) => state.deleteMessageInfo
  );
  const { errorDeleteMessage, deleteMessageData } = deleteMessageInfo;

  useEffect(() => {
    dispatch({ type: DELETE_MESSAGE_RESET });
    if (deleteMessageData) {
      toast(deleteMessageData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      dispatch(getMessages());
    } else if (errorDeleteMessage) {
      toast(errorDeleteMessage, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [deleteMessageData, errorDeleteMessage]);

  const deleteQueryInfo = useSelector(
    (state) => state.deleteQueryInfo
  );
  const { errorDeleteQuery, deleteQueryData } = deleteQueryInfo;

  useEffect(() => {
    dispatch({ type: DELETE_QUERY_RESET });
    if (deleteQueryData) {
      toast(deleteQueryData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      dispatch(getQueries());
    } else if (errorDeleteQuery) {
      toast(errorDeleteQuery, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [deleteQueryData, errorDeleteQuery]);

  const [updateQueryModal, setUpdateQueryModal] = useState(false);
  const [queryId, setQueryId] = useState('');
  const [queryStatus, setQueryStatus] = useState('');
  const [queryAction, setQueryAction] = useState('');

  const openQueryModal = (data) => {
    setUpdateQueryModal(true);
    setQueryId(data._id);
    setQueryStatus(data.status);
    setQueryAction(data.action);
  };

  const closeQueryModal = () => {
    setUpdateQueryModal(false);
    setQueryId('');
    setQueryStatus('');
    setQueryAction('');
  };

  const updateQueryHandler = () => {
    if (!queryId || !queryStatus) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    if (
      queryStatus === 'In Progress' ||
      queryStatus === 'Completed'
    ) {
      if (!queryAction) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        });
        return;
      }
    }

    dispatch(updateQuery(queryId, queryStatus, queryAction));
  };

  const updateQueryInfo = useSelector(
    (state) => state.updateQueryInfo
  );
  const { loadingUpdateQuery, errorUpdateQuery, updateQueryData } =
    updateQueryInfo;

  useEffect(() => {
    dispatch({ type: UPDATE_QUERY_RESET });
    if (updateQueryData) {
      toast(updateQueryData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      dispatch(getQueries());
      closeQueryModal();
    } else if (errorUpdateQuery) {
      toast(errorUpdateQuery, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [updateQueryData, errorUpdateQuery]);

  const [feedbackResponseModal, setFeedbackResponseModal] =
    useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [responseContent, setResponseContent] = useState('');

  const closeResponseModal = () => {
    setFeedbackResponseModal(false);
    setFeedbackId('');
    setResponseStatus('');
    setResponseContent('');
  };

  const openFeedbackResponseModal = (data) => {
    setFeedbackResponseModal(true);
    setFeedbackId(data._id);
  };

  const updateResponseHandler = () => {
    if (!responseStatus || !responseContent) {
      toast('All the fields are mandatory', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    dispatch(
      submitFeedbackResponse(
        feedbackId,
        responseStatus,
        responseContent
      )
    );
  };

  const submitFeedbackResponseInfo = useSelector(
    (state) => state.submitFeedbackResponseInfo
  );
  const {
    loadingSubmitFeedbackResponse,
    submitFeedbackResponseData,
    errorSubmitFeedbackResponse,
  } = submitFeedbackResponseInfo;

  useEffect(() => {
    dispatch({ type: SUBMIT_RESPONSE_FEEDBACK_RESET });
    if (submitFeedbackResponseData) {
      toast(submitFeedbackResponseData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      closeResponseModal();
      // dispatch(getFeedbacks());
      setArr(
        data.map((el) =>
          el._id === feedbackId
            ? {
                ...el,
                status: responseStatus,
                response: responseContent,
              }
            : el
        )
      );
    } else if (errorSubmitFeedbackResponse) {
      toast(errorSubmitFeedbackResponse, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [submitFeedbackResponseData, errorSubmitFeedbackResponse]);

  useEffect(() => {
    setArr(data);
  }, [data]);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={arr}
        actions={
          type === 'User'
            ? [
                {
                  icon: Edit,
                  tooltip: 'Generate Password',
                  onClick: (event, rowData) => openModal(rowData._id),
                },
              ]
            : type === 'Messages'
            ? [
                {
                  icon: Delete,
                  tooltip: 'Delete',
                  onClick: (event, rowData) => {
                    dispatch(deleteMessage(rowData._id));
                  },
                },
              ]
            : type === 'Queries'
            ? [
                {
                  icon: Update,
                  tooltip: 'Update',
                  onClick: (event, rowData) => {
                    openQueryModal(rowData);
                  },
                },
                {
                  icon: Delete,
                  tooltip: 'Delete',
                  onClick: (event, rowData) => {
                    dispatch(deleteQuery(rowData._id));
                  },
                },
              ]
            : type === 'Feedbacks'
            ? [
                {
                  icon: Update,
                  tooltip: 'Update',
                  onClick: (event, rowData) => {
                    openFeedbackResponseModal(rowData);
                  },
                },
              ]
            : []
        }
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
          actionsColumnIndex: -1,
        }}
      />
      {modal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg font-semibold">
                    New Password
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="text-sm">
                    Are you sure, you want to generate new password
                    for this user?
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setModal(false);
                      setUserId('');
                    }}
                  >
                    No
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                    type="button"
                    onClick={generateNewPasswordHandler}
                    disabled={loadingNewPasswordUser}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {passwordModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg font-semibold">Password</h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Input
                    width="flex-1"
                    name="Password *"
                    value={password}
                    onChange={null}
                    disabled
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closePasswordModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {updateQueryModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg font-semibold">
                    Update Query
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Select
                    width="w-full mb-4"
                    name="Type *"
                    value={queryStatus}
                    onChange={(e) => setQueryStatus(e.target.value)}
                    options={[
                      {
                        id: 'Pending',
                        title: 'Pending',
                      },
                      {
                        id: 'In Progress',
                        title: 'In Progress',
                      },
                      { id: 'Completed', title: 'Completed' },
                    ]}
                  />
                  <Input
                    width="w-full"
                    name="Action"
                    value={queryAction}
                    onChange={(e) => setQueryAction(e.target.value)}
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeQueryModal}
                  >
                    No
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                    type="button"
                    onClick={updateQueryHandler}
                    disabled={loadingUpdateQuery}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {feedbackResponseModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg font-semibold">
                    Feedback Response
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Select
                    width="w-full mb-4"
                    name="Status *"
                    value={responseStatus}
                    onChange={(e) =>
                      setResponseStatus(e.target.value)
                    }
                    options={[
                      {
                        id: 'In Review',
                        title: 'In Review',
                      },
                      {
                        id: 'Resolved',
                        title: 'Resolved',
                      },
                    ]}
                  />
                  <Input
                    width="w-full"
                    name="Response"
                    value={responseContent}
                    onChange={(e) =>
                      setResponseContent(e.target.value)
                    }
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeResponseModal}
                  >
                    No
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                    type="button"
                    onClick={updateResponseHandler}
                    disabled={loadingSubmitFeedbackResponse}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default MatTable;
