import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import { PieChart } from 'react-minimal-pie-chart';

import Loading from '../components/Loading';
import MatTable from '../components/MatTable';
import { getFeedbacks } from '../actions/feedbackActions';
import { GET_FEEDBACKS_RESET } from '../constants/feedbackConstants';
import Button from '../components/Button';
import { Select } from '../components/Select';
import DatePicker from 'react-datepicker';

const Feedback = ({ history }) => {
  // * States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState();

  // * Initialization
  const dispatch = useDispatch();

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/');
    }
  }, [userInfo, history]);

  useEffect(() => {
    setLoading(true);
    dispatch(getFeedbacks());
  }, []);

  // * Get Feedbacks
  const getFeedbacksInfo = useSelector(
    (state) => state.getFeedbacksInfo
  );
  const { errorGetFeedbacks, getFeedbacksData } = getFeedbacksInfo;

  useEffect(() => {
    dispatch({ type: GET_FEEDBACKS_RESET });
    if (getFeedbacksData) {
      setLoading(false);
      setData(getFeedbacksData);
    } else if (errorGetFeedbacks) {
      setLoading(false);
      toast(errorGetFeedbacks, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [getFeedbacksData, errorGetFeedbacks]);

  // const openFeedback = (data) => {
  //   const result = data.questions;
  //   const found = result.find((el) => el.question === 'Feedback');

  //   !found &&
  //     result.push({ question: 'Feedback', answer: data.feedback });

  //   setFeedback(result);
  //   setFeedbackModal(true);
  // };

  const closeFeedbackModal = () => {
    setFeedback(null);
    setFeedbackModal(false);
  };

  const headCells = [
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'mobile',
      title: 'Mobile',
    },
    {
      field: 'verification',
      title: 'Mobile Verification',
    },
    {
      field: 'status',
      title: 'Status',
      render: (rowData) => {
        return (
          <p
            className={
              (rowData.status === 'Negative' ||
                rowData.status === 'In Review' ||
                rowData.status === 'Resolved') &&
              rowData.totalPoints < rowData.totalQuestions &&
              'bg-red-500 text-white p-4 rounded-md'
            }
          >
            {rowData.status}
          </p>
        );
      },
    },
    {
      field: 'Valet Services.',
      title: 'Valet Services.',
    },
    {
      field: 'Quality of Car Park Staff.',
      title: 'Quality of Car Park Staff.',
    },
    {
      field: 'Concierge Services.',
      title: 'Concierge Services.',
    },
    {
      field: 'Mall Ambience.',
      title: 'Mall Ambience.',
    },
    {
      field: 'Washroom Facilities.',
      title: 'Washroom Facilities.',
    },
    {
      field: 'Security Arrangements.',
      title: 'Security Arrangements.',
    },
    {
      field: 'Quality of Mall Staff.',
      title: 'Quality of Mall Staff.',
    },
    {
      field: 'feedback',
      title: 'Feedback',
    },
    {
      field: 'response',
      title: 'Response',
    },
    {
      field: 'createdAt',
      title: 'Created At',
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
    },
  ];

  const feedbackHeadCells = [
    {
      field: 'question',
      title: 'Question',
    },
    {
      field: 'answer',
      title: 'Answer',
    },
  ];

  const handleChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-full h-full">
        <h1 className="text-2xl font-semibold">Feebacks</h1>
        <div className="bg-white shadow-md rounded px-8 py-4 m-4 flex items-center justify-between">
          <div
            className="flex items-center space-x-4"
            style={{ zIndex: 100 }}
          >
            <div className="flex flex-col space-y-1">
              <p className="text-sm">Date Range</p>
              <div
                style={{
                  borderColor: 'gray',
                  borderWidth: 0.8,
                  borderRadius: 2,
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={handleChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <p className="text-sm">Status</p>
              <Select
                width="w-full mb-4"
                name="Status *"
                options={[
                  { id: 'Started', title: 'Started' },
                  { id: 'Positive', title: 'Positive' },
                  { id: 'Negative', title: 'Negative' },
                  { id: 'In Review', title: 'In Review' },
                  { id: 'Resolved', title: 'Resolved' },
                ]}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </div>
          </div>
          <Button
            custom="py-2"
            type="button"
            onClick={() => {
              dispatch(getFeedbacks(startDate, endDate, status));
            }}
            text="Search"
          />
        </div>
        <div className="flex items-center justify-evenly mb-[-20px]">
          <div className="flex flex-col items-center justify-center my-6">
            <p className="text-md font-semibold text-black">
              Total Feedbacks ({data.length})
            </p>
            <div className="flex items-center">
              <PieChart
                animate
                animationDuration={500}
                animationEasing="ease-out"
                data={[
                  {
                    title:
                      // 'Pending ' +
                      // '(' +
                      data.filter((el) => el.status === 'Started')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status === 'Started'
                    ).length,
                    color: '#d0a7a6',
                  },
                  {
                    title:
                      // 'Complete ' +
                      // '(' +
                      data.filter((el) => el.status !== 'Started')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status !== 'Started'
                    ).length,
                    color: '#00c3e3',
                  },
                ]}
                lengthAngle={360}
                paddingAngle={0}
                radius={60}
                startAngle={0}
                label={(data) => data.dataEntry.title}
                labelPosition={65}
                labelStyle={{
                  fontSize: '8px',
                  fontWeight: '400',
                }}
                viewBoxSize={[150, 150]}
                center={[75, 70]}
              />
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#d0a7a6]"></div>
                  <p className="text-sm">Pending</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#00c3e3]"></div>
                  <p className="text-sm">Completed</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center my-6">
            <p className="text-md font-semibold text-black">
              Status Wise Chart (
              {data.filter((el) => el.status !== 'Started').length})
            </p>
            <div className="flex items-center">
              <PieChart
                animate
                animationDuration={500}
                animationEasing="ease-out"
                data={[
                  {
                    title:
                      // 'Submitted ' +
                      // '(' +
                      data.filter((el) => el.status === 'Positive')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status === 'Positive'
                    ).length,
                    color: '#00c3e3',
                  },
                  {
                    title:
                      // 'Submitted ' +
                      // '(' +
                      data.filter((el) => el.status === 'Negative')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status === 'Negative'
                    ).length,
                    color: '#d0ae8b',
                  },
                  {
                    title:
                      // 'In Review ' +
                      // '(' +
                      data.filter((el) => el.status === 'In Review')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status === 'In Review'
                    ).length,
                    color: '#f1807e',
                  },
                  {
                    title:
                      // 'Resolved ' +
                      // '(' +
                      data.filter((el) => el.status === 'Resolved')
                        .length,
                    // ')',
                    value: data.filter(
                      (el) => el.status === 'Resolved'
                    ).length,
                    color: '#90EE90',
                  },
                ]}
                lengthAngle={360}
                paddingAngle={0}
                radius={60}
                startAngle={0}
                label={(data) =>
                  data.dataEntry.value > 0 && data.dataEntry.title
                }
                labelPosition={65}
                labelStyle={{
                  fontSize: '8px',
                  fontWeight: '400',
                }}
                viewBoxSize={[150, 150]}
                center={[75, 70]}
              />
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#00c3e3]"></div>
                  <p className="text-sm">Positive</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#d0ae8b]"></div>
                  <p className="text-sm">Negative</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#f1807e]"></div>
                  <p className="text-sm">In Review</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-4 bg-[#90EE90]"></div>
                  <p className="text-sm">Resolved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MatTable
          headCells={headCells}
          data={data}
          type="Feedbacks"
        />
        {feedbackModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-2xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">
                      Feedback
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex flex-wrap items-center">
                    <MatTable
                      headCells={feedbackHeadCells}
                      data={feedback}
                      type="Feedbacks"
                    />
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeFeedbackModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </div>
    </>
  );
};

export default Feedback;
