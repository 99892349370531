import {
  ADD_TODO_FAIL,
  ADD_TODO_REQUEST,
  ADD_TODO_RESET,
  ADD_TODO_SUCCESS,
  GET_TODOS_FAIL,
  GET_TODOS_REQUEST,
  GET_TODOS_RESET,
  GET_TODOS_SUCCESS,
} from '../constants/todoConstants'

export const getTodosReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TODOS_REQUEST:
      return { loadingGetTodos: true }
    case GET_TODOS_SUCCESS:
      return { loadingGetTodos: false, getTodosData: action.payload }
    case GET_TODOS_FAIL:
      return { loadingGetTodos: false, errorGetTodos: action.payload }
    case GET_TODOS_RESET:
      return {}
    default:
      return state
  }
}

export const addTodoReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TODO_REQUEST:
      return { loadingAddTodo: true }
    case ADD_TODO_SUCCESS:
      return { loadingAddTodo: false, addTodoData: action.payload }
    case ADD_TODO_FAIL:
      return { loadingAddTodo: false, errorAddTodo: action.payload }
    case ADD_TODO_RESET:
      return {}
    default:
      return state
  }
}
