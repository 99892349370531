import axios from 'axios';
import {
  GET_FEEDBACKS_FAIL,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_SUCCESS,
  SUBMIT_RESPONSE_FEEDBACK_FAIL,
  SUBMIT_RESPONSE_FEEDBACK_REQUEST,
  SUBMIT_RESPONSE_FEEDBACK_SUCCESS,
} from '../constants/feedbackConstants';
import { BASE_URL } from '../constants/URL.js';

export const getFeedbacks =
  (startDate, endDate, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_FEEDBACKS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/feedbacks?startDate=${startDate}&endDate=${endDate}&status=${status}`,
        config
      );

      dispatch({
        type: GET_FEEDBACKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEEDBACKS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const submitFeedbackResponse =
  (id, status, response) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBMIT_RESPONSE_FEEDBACK_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/feedbacks/${id}`,
        { status, response },
        config
      );

      dispatch({
        type: SUBMIT_RESPONSE_FEEDBACK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUBMIT_RESPONSE_FEEDBACK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
