export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL';
export const GET_BANNERS_RESET = 'GET_BANNERS_RESET';

export const ADD_BANNER_REQUEST = 'ADD_BANNER_REQUEST';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_FAIL = 'ADD_BANNER_FAIL';
export const ADD_BANNER_RESET = 'ADD_BANNER_RESET';

export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAIL = 'UPDATE_BANNER_FAIL';
export const UPDATE_BANNER_RESET = 'UPDATE_BANNER_RESET';

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_FAIL = 'DELETE_BANNER_FAIL';
export const DELETE_BANNER_RESET = 'DELETE_BANNER_RESET';
