import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Select from 'react-select'
import readXlsxFile from 'read-excel-file'
import moment from 'moment'
import { Image } from 'cloudinary-react'

import Button from '../components/Button'
import { Select as MySelect } from '../components/Select'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import {
  addMessage,
  getMessages,
  uploadMessages,
} from '../actions/messageActions'
import {
  ADD_MESSAGE_RESET,
  GET_MESSAGES_RESET,
  UPLOAD_MESSAGE_RESET,
} from '../constants/messageConstants'
import { getUsers } from '../actions/userActions'

const Message = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addMessageModal, setAddMessageModal] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDesciption] = useState('')
  const [image, setImage] = useState('')
  const [type, setType] = useState('')
  const [imageModal, setImageModal] = useState(false)
  const [department, setDepartment] = useState('')
  const [user, setUser] = useState('')
  const [allUsers, setAllUsers] = useState([])
  const [allDepartments, setAllDepartments] = useState([])
  const [excelData, setExcelData] = useState([])
  const [date, setDate] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getMessages())
    dispatch(getUsers())
  }, [])

  // * Get Messages
  const getMessagesInfo = useSelector((state) => state.getMessagesInfo)
  const { errorGetMessages, getMessagesData } = getMessagesInfo

  useEffect(() => {
    dispatch({ type: GET_MESSAGES_RESET })
    if (getMessagesData) {
      setLoading(false)
      setData(getMessagesData)
    } else if (errorGetMessages) {
      setLoading(false)
      toast(errorGetMessages, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getMessagesData, errorGetMessages])

  // * Get Users
  const getUsersInfo = useSelector((state) => state.getUsersInfo)
  const { errorGetUsers, getUsersData } = getUsersInfo

  useEffect(() => {
    if (getUsersData) {
      let data = []
      getUsersData?.forEach((usr) => {
        data.push({
          value: usr._id,
          label: `${usr.username} - ${usr.name}`,
          department: usr.department,
          role: usr.role,
        })
      })

      setAllUsers(data)
    } else if (errorGetUsers) {
      toast(errorGetUsers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getUsersData, errorGetUsers])

  useEffect(() => {
    if (userInfo) {
      let dpts = []

      dpts = userInfo?.department?.map((dpt) => {
        return { id: dpt, title: dpt }
      })

      if (userInfo?.role === 'Super Admin') {
        dpts = [{ id: 'All', title: 'All' }].concat(dpts)
      }

      setAllDepartments(dpts)
    }
  }, [userInfo])

  useEffect(() => {
    if (department !== 'All') {
      const dptUsers = getUsersData?.filter((usr) => {
        return usr.department.includes(department) && usr.role === 'Employee'
      })

      let data = []
      dptUsers?.forEach((usr) => {
        data.push({ value: usr._id, label: `${usr.username} - ${usr.name}` })
      })

      setAllUsers(data)
    }
  }, [department])

  const closeAddMessageModal = () => {
    setTitle('')
    setDesciption('')
    setImage('')
    setType('')
    setDepartment('')
    setUser('')
    setDate('')
    setAddMessageModal(false)
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = ''
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const addMessageHandler = async () => {
    if (!title || !type || !description || !department || !date) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    if (new Date(date).toString() === 'Invalid Date') {
      toast('Invalid date entered', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    let base64 = ''

    if (image) {
      base64 = await getBase64(image)
    }

    dispatch(
      addMessage(
        title,
        description,
        type,
        base64,
        department,
        user.length ? user.map((usr) => usr.value) : [],
        moment(date).format('DD-MM-YYYY').toString()
      )
    )
  }

  const addMessageInfo = useSelector((state) => state.addMessageInfo)
  const { loadingAddMessage, errorAddMessage, addMessageData } = addMessageInfo

  useEffect(() => {
    dispatch({ type: ADD_MESSAGE_RESET })
    if (addMessageData) {
      closeAddMessageModal()
      toast(addMessageData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getMessages())
      }, 1000)
    } else if (errorAddMessage) {
      toast(errorAddMessage, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addMessageData, errorAddMessage])

  const headCells = [
    {
      field: 'title',
      title: 'Title',
    },
    {
      field: 'description',
      title: 'Description',
    },
    {
      field: 'image',
      title: 'Image',
      render: (rowData) => {
        return (
          <>
            {rowData.image ? (
              <div
                className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                onClick={() => openImage(rowData.image)}
              >
                View
              </div>
            ) : (
              <p>N/A</p>
            )}
          </>
        )
      },
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'department',
      title: 'Department',
      render: (rowData) => {
        return <p className='text-sm'>{rowData.department.join(', ')}</p>
      },
    },
    {
      field: 'date',
      title: 'Date',
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        )
      },
    },
  ]

  const openImage = (img) => {
    setImage(img)
    setImageModal(true)
  }

  const closeImageModal = () => {
    setImage([])
    setImageModal(false)
  }

  const readExcelFile = (file) => {
    readXlsxFile(file).then((rows) => {
      setLoading(true)

      if (!rows.length) {
        toast('No data found', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
        return
      }

      if (
        rows?.[0]?.[0] !== 'Title' ||
        rows?.[0]?.[1] !== 'Description' ||
        rows?.[0]?.[2] !== 'Type' ||
        rows?.[0]?.[3] !== 'Department' ||
        rows?.[0]?.[4] !== 'Date'
      ) {
        toast('Invalid header row', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
        return
      }

      rows.splice(0, 1)

      const data = rows.map((row, index) => {
        let status = 'Success'
        let reason = ''

        // * 1st and 2nd column
        if (!row?.[0] || !row?.[1]) {
          status = 'Error'
          reason = 'Invalid title or description'
        }

        // * 3rd column
        if (
          !(row?.[2] === 'General Communication' || row?.[2] === 'Training' || row?.[2] === 'Meeting')
        ) {
          status = 'Error'
          reason = reason + ' Invalid calendar type'
        }

        let allDpts = userInfo?.department

        // * 4th column
        if (userInfo?.role === 'Super Admin' && row?.[3] === 'All') {
          allDpts.push('All')
        }

        if (!allDpts.includes(row?.[3])) {
          status = 'Error'
          reason = reason + ' Invalid department'
        }

        // * 5th column
        if (new Date(row?.[4]).toString() === 'Invalid Date') {
          status = 'Error'
          reason = reason + ' Invalid date'
        }

        return {
          index: index + 1,
          title: row?.[0],
          description: row?.[1],
          type: row?.[2],
          department: row?.[3],
          date: moment(row?.[4]).format('DD-MM-YYYY')?.toString(),
          status,
          reason,
        }
      })

      setLoading(false)
      setExcelData(data)
    })
  }

  const submitExcelData = () => {
    const error = excelData.filter((el) => el.status === 'Error')

    if (error.length > 0) {
      toast('Error found in the data', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    dispatch(uploadMessages(excelData))
  }

  const uploadMessageInfo = useSelector((state) => state.uploadMessageInfo)
  const { loadingUploadMessage, errorUploadMessage, uploadMessageData } =
    uploadMessageInfo

  useEffect(() => {
    dispatch({ type: UPLOAD_MESSAGE_RESET })
    if (uploadMessageData) {
      setExcelData([])
      toast(uploadMessageData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getMessages())
      }, 1000)
    } else if (errorUploadMessage) {
      toast(errorUploadMessage, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [uploadMessageData, errorUploadMessage])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Messages</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2 mr-4'
              type='button'
              onClick={() => setAddMessageModal(true)}
              text='Add a Message'
            />
            <Input
              type='file'
              id='input'
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={(e) => {
                const file = e.target.files[0]
                readExcelFile(file)
              }}
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Messages' />
        {addMessageModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add Message</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='w-full'
                      name='Title *'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <Input
                      width='w-full mb-4 mt-4'
                      name='Description *'
                      value={description}
                      onChange={(e) => setDesciption(e.target.value)}
                    />
                    <Input
                      width='flex-1'
                      type='file'
                      name='PDF'
                      accept='application/pdf'
                      // value={image}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <Input
                      width='w-full mb-4 mt-4'
                      name='Date *'
                      type='date'
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <MySelect
                      width='w-full mb-4'
                      name='Type *'
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      options={[
                        {
                          id: 'General Communication',
                          title: 'General Communication',
                        },
                        { id: 'Training', title: 'Training' },
                      ]}
                    />
                    <MySelect
                      width='w-full mb-4'
                      name='Department *'
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      options={allDepartments}
                    />
                    {!(department === '' || department === 'All') && (
                      <Select
                        isMulti
                        options={allUsers}
                        value={user}
                        onChange={(e) => setUser(e)}
                      />
                    )}
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddMessageModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addMessageHandler}
                      disabled={loadingAddMessage}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {imageModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-2xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Image</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex flex-wrap items-center'>
                    <Image
                      cloudName='emporio'
                      publicId={image}
                      key={image}
                      width='280'
                      height='180'
                      crop='scale'
                      className='m-4'
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeImageModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {excelData.length > 0 && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Uploaded data</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <MatTable
                      headCells={[
                        {
                          field: 'index',
                          title: 'Index',
                        },
                        {
                          field: 'title',
                          title: 'Title',
                        },
                        {
                          field: 'description',
                          title: 'Description',
                        },
                        {
                          field: 'type',
                          title: 'Type',
                        },
                        {
                          field: 'department',
                          title: 'Department',
                        },
                        {
                          field: 'date',
                          title: 'Date',
                        },
                        {
                          field: 'status',
                          title: 'Status',
                        },
                        {
                          field: 'reason',
                          title: 'Reason',
                        },
                      ]}
                      data={excelData}
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setExcelData([])}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={submitExcelData}
                      disabled={loadingUploadMessage}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Message
