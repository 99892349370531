import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';

import Button from '../components/Button';
import { Select as MySelect } from '../components/Select';
import Input from '../components/Input';
import Loading from '../components/Loading';
import MatTable from '../components/MatTable';
import { addTodo, getTodos } from '../actions/todoActions';
import {
  ADD_MESSAGE_RESET,
  GET_MESSAGES_RESET,
} from '../constants/messageConstants';
import moment from 'moment';
import { Image } from 'cloudinary-react';
import { getUsers } from '../actions/userActions';
import { GET_TODOS_RESET } from '../constants/todoConstants';

const Todo = ({ history }) => {
  // * States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addTodoModal, setAddTodoModal] = useState(false);
  const [message, setMessage] = useState('');
  const [department, setDepartment] = useState('');
  const [user, setUser] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);

  // * Initialization
  const dispatch = useDispatch();

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/');
    }
  }, [userInfo, history]);

  useEffect(() => {
    setLoading(true);
    dispatch(getTodos());
    dispatch(getUsers());
  }, []);

  // * Get Todos
  const getTodosInfo = useSelector((state) => state.getTodosInfo);
  const { errorGetTodos, getTodosData } = getTodosInfo;

  useEffect(() => {
    dispatch({ type: GET_TODOS_RESET });
    if (getTodosData) {
      setLoading(false);
      setData(getTodosData);
    } else if (errorGetTodos) {
      setLoading(false);
      toast(errorGetTodos, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [getTodosData, errorGetTodos]);

  // * Get Users
  const getUsersInfo = useSelector((state) => state.getUsersInfo);
  const { errorGetUsers, getUsersData } = getUsersInfo;

  useEffect(() => {
    if (getUsersData) {
      let data = [];
      getUsersData?.forEach((usr) => {
        data.push({
          value: usr._id,
          label: `${usr.username} - ${usr.name}`,
          department: usr.department,
          role: usr.role,
        });
      });

      setAllUsers(data);
    } else if (errorGetUsers) {
      toast(errorGetUsers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [getUsersData, errorGetUsers]);

  useEffect(() => {
    if (userInfo) {
      let dpts = [];

      dpts = userInfo?.department?.map((dpt) => {
        return { id: dpt, title: dpt };
      });

      if (userInfo?.role === 'Super Admin') {
        dpts = [{ id: 'All', title: 'All' }].concat(dpts);
      }

      setAllDepartments(dpts);
    }
  }, [userInfo]);

  useEffect(() => {
    if (department !== 'All') {
      const dptUsers = getUsersData?.filter((usr) => {
        return (
          usr.department.includes(department) &&
          usr.role === 'Employee'
        );
      });

      let data = [];
      dptUsers?.forEach((usr) => {
        data.push({
          value: usr._id,
          label: `${usr.username} - ${usr.name}`,
        });
      });

      setAllUsers(data);
    }
  }, [department]);

  const closeAddTodoModal = () => {
    setMessage('');
    setDepartment('');
    setUser('');
    setAddTodoModal(false);
  };

  const addTodoHandler = async () => {
    if (!message || !department) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    } else {
      dispatch(
        addTodo(
          message,
          department,
          user.length ? user.map((usr) => usr.value) : []
        )
      );
    }
  };

  const addTodoInfo = useSelector((state) => state.addTodoInfo);
  const { loadingAddTodo, errorAddTodo, addTodoData } = addTodoInfo;

  useEffect(() => {
    dispatch({ type: ADD_MESSAGE_RESET });
    if (addTodoData) {
      closeAddTodoModal();
      toast(addTodoData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      setTimeout(() => {
        dispatch(getTodos());
      }, 1000);
    } else if (errorAddTodo) {
      toast(errorAddTodo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [addTodoData, errorAddTodo]);

  const headCells = [
    {
      field: 'message',
      title: 'Message',
    },
    {
      field: 'department',
      title: 'Department',
    },
    {
      field: 'completedBy',
      title: 'Completed By',
      render: (rowData) => {
        return (
          <p className="text-sm">
            {rowData.completedBy
              ? `${rowData.completedBy.name} (${rowData.completedBy.username})`
              : 'N/A'}
          </p>
        );
      },
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return (
          <p className="text-sm">
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        );
      },
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      render: (rowData) => {
        return (
          <p className="text-sm">
            {moment(rowData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-full h-full">
        <h1 className="text-2xl font-semibold">To-Do</h1>
        <div className="bg-white shadow-md rounded px-8 py-4 my-4">
          <div className="flex justify-end">
            <Button
              custom="py-2"
              type="button"
              onClick={() => setAddTodoModal(true)}
              text="Add a To-Do"
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type="Todos" />
        {addTodoModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">
                      Add Todo
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <Input
                      width="w-full mb-4"
                      name="Message *"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <MySelect
                      width="w-full mb-4"
                      name="Department *"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      options={allDepartments}
                    />
                    {!(department === '' || department === 'All') && (
                      <Select
                        isMulti
                        options={allUsers}
                        value={user}
                        onChange={(e) => setUser(e)}
                      />
                    )}
                    <p className="text-sm">
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeAddTodoModal}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                      type="button"
                      onClick={addTodoHandler}
                      disabled={loadingAddTodo}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </div>
    </>
  );
};

export default Todo;
