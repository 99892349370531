export const GET_FEEDBACKS_REQUEST = 'GET_FEEDBACKS_REQUEST';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_FAIL = 'GET_FEEDBACKS_FAIL';
export const GET_FEEDBACKS_RESET = 'GET_FEEDBACKS_RESET';

export const SUBMIT_RESPONSE_FEEDBACK_REQUEST =
  'SUBMIT_RESPONSE_FEEDBACK_REQUEST';
export const SUBMIT_RESPONSE_FEEDBACK_SUCCESS =
  'SUBMIT_RESPONSE_FEEDBACK_SUCCESS';
export const SUBMIT_RESPONSE_FEEDBACK_FAIL =
  'SUBMIT_RESPONSE_FEEDBACK_FAIL';
export const SUBMIT_RESPONSE_FEEDBACK_RESET =
  'SUBMIT_RESPONSE_FEEDBACK_RESET';
