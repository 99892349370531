export const GET_QUERY_REQUEST = 'GET_QUERY_REQUEST'
export const GET_QUERY_SUCCESS = 'GET_QUERY_SUCCESS'
export const GET_QUERY_FAIL = 'GET_QUERY_FAIL'
export const GET_QUERY_RESET = 'GET_QUERY_RESET'

export const DELETE_QUERY_REQUEST = 'DELETE_QUERY_REQUEST'
export const DELETE_QUERY_SUCCESS = 'DELETE_QUERY_SUCCESS'
export const DELETE_QUERY_FAIL = 'DELETE_QUERY_FAIL'
export const DELETE_QUERY_RESET = 'DELETE_QUERY_RESET'

export const UPDATE_QUERY_REQUEST = 'UPDATE_QUERY_REQUEST'
export const UPDATE_QUERY_SUCCESS = 'UPDATE_QUERY_SUCCESS'
export const UPDATE_QUERY_FAIL = 'UPDATE_QUERY_FAIL'
export const UPDATE_QUERY_RESET = 'UPDATE_QUERY_RESET'
