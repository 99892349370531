import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { getQrCodes, addQrCode, updateQrCode } from '../actions/qrcodeActions'
import {
  GET_QRCODES_RESET,
  ADD_QRCODE_RESET,
  UPDATE_QRCODE_RESET,
} from '../constants/qrcodeConstants'
import moment from 'moment'
import QRCode from 'react-qr-code'
import { Edit } from '@material-ui/icons'

const QrCode = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addQrCodeModal, setAddQrCodeModal] = useState(false)
  const [name, setName] = useState('')
  const [questions, setQuestions] = useState('')
  const [qrModal, setQrModal] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [updateModal, setUpdateModal] = useState(false)
  const [id, setId] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getQrCodes())
  }, [])

  // * Get QrCodes
  const getQrCodesInfo = useSelector((state) => state.getQrCodesInfo)
  const { errorGetQrCodes, getQrCodesData } = getQrCodesInfo

  useEffect(() => {
    dispatch({ type: GET_QRCODES_RESET })
    if (getQrCodesData) {
      setLoading(false)
      setData(getQrCodesData)
    } else if (errorGetQrCodes) {
      setLoading(false)
      toast(errorGetQrCodes, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getQrCodesData, errorGetQrCodes])

  const closeAddQrCodeModal = () => {
    setName('')
    setQuestions('')
    setAddQrCodeModal(false)
  }

  const addQrCodeHandler = async () => {
    if (!name || !questions) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    dispatch(addQrCode(name, questions))
  }

  const addQrCodeInfo = useSelector((state) => state.addQrCodeInfo)
  const { loadingAddQrCode, errorAddQrCode, addQrCodeData } = addQrCodeInfo

  useEffect(() => {
    dispatch({ type: ADD_QRCODE_RESET })
    if (addQrCodeData) {
      closeAddQrCodeModal()
      toast('QR Code added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getQrCodes())
      }, 500)
    } else if (errorAddQrCode) {
      toast(errorAddQrCode, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addQrCodeData, errorAddQrCode])

  const openQrCode = (data) => {
    setQrCode(data)
    setQrModal(true)
  }

  const closeQrModal = () => {
    setQrCode('')
    setQrModal(false)
  }

  const openUpdateQrCode = (data) => {
    setId(data._id)
    setName(data.name)
    setQuestions(data.questions)
    setUpdateModal(true)
  }

  const closeUpdateQrCode = () => {
    setId('')
    setName('')
    setQuestions('')
    setUpdateModal(false)
  }

  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: '_id',
      title: 'QR Code',
      render: (rowData) => {
        return (
          <>
            {rowData._id ? (
              <div
                className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                onClick={() => openQrCode(rowData._id)}
              >
                View
              </div>
            ) : (
              'N/A'
            )}
          </>
        )
      },
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        )
      },
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {moment(rowData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        )
      },
    },
    {
      field: '_id',
      title: 'Actions',
      render: (rowData) => {
        return (
          <>
            {rowData._id ? (
              <div
                className='cursor-pointer'
                onClick={() => openUpdateQrCode(rowData)}
              >
                <Edit />
              </div>
            ) : (
              'N/A'
            )}
          </>
        )
      },
    },
  ]

  const updateQrCodeHandler = async () => {
    if (!id || !questions) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    dispatch(updateQrCode(id, questions))
  }

  const updateQrCodeInfo = useSelector((state) => state.updateQrCodeInfo)
  const { loadingUpdateQrCode, errorUpdateQrCode, updateQrCodeData } =
    updateQrCodeInfo

  useEffect(() => {
    dispatch({ type: UPDATE_QRCODE_RESET })
    if (updateQrCodeData) {
      closeUpdateQrCode()
      toast('QR Code updated successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getQrCodes())
      }, 500)
    } else if (errorUpdateQrCode) {
      toast(errorUpdateQrCode, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateQrCodeData, errorUpdateQrCode])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>QR Codes</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => setAddQrCodeModal(true)}
              text='Add a QR Code'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='QR Codes' />
        {addQrCodeModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add QR Code</h3>
                  </div>
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='w-full mb-4'
                      name='Name *'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      width='w-full mb-4'
                      name='Questions *'
                      value={questions}
                      onChange={(e) => setQuestions(e.target.value)}
                    />
                    <p className='text-sm'>
                      All the fields with * are mandatory. In questions use ","
                      to separate.
                    </p>
                  </div>
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddQrCodeModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addQrCodeHandler}
                      disabled={loadingAddQrCode}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {qrModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>QR Code</h3>
                  </div>
                  <div className='relative p-6 flex-auto'>
                    <QRCode
                      value={`https://api.te.parkoye.com/qrcodes/start/${qrCode}`}
                    />
                  </div>
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeQrModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {updateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>{name}</h3>
                  </div>
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='w-full mb-4'
                      name='Questions *'
                      value={questions}
                      onChange={(e) => setQuestions(e.target.value)}
                    />
                    <p className='text-sm'>In questions use "," to separate.</p>
                  </div>
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeUpdateQrCode}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={updateQrCodeHandler}
                      disabled={loadingUpdateQrCode}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default QrCode
