export const GET_QRCODES_REQUEST = 'GET_QRCODES_REQUEST'
export const GET_QRCODES_SUCCESS = 'GET_QRCODES_SUCCESS'
export const GET_QRCODES_FAIL = 'GET_QRCODES_FAIL'
export const GET_QRCODES_RESET = 'GET_QRCODES_RESET'

export const ADD_QRCODE_REQUEST = 'ADD_QRCODE_REQUEST'
export const ADD_QRCODE_SUCCESS = 'ADD_QRCODE_SUCCESS'
export const ADD_QRCODE_FAIL = 'ADD_QRCODE_FAIL'
export const ADD_QRCODE_RESET = 'ADD_QRCODE_RESET'

export const UPDATE_QRCODE_REQUEST = 'UPDATE_QRCODE_REQUEST'
export const UPDATE_QRCODE_SUCCESS = 'UPDATE_QRCODE_SUCCESS'
export const UPDATE_QRCODE_FAIL = 'UPDATE_QRCODE_FAIL'
export const UPDATE_QRCODE_RESET = 'UPDATE_QRCODE_RESET'
