import {
  GET_FEEDBACKS_FAIL,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_RESET,
  GET_FEEDBACKS_SUCCESS,
  SUBMIT_RESPONSE_FEEDBACK_FAIL,
  SUBMIT_RESPONSE_FEEDBACK_REQUEST,
  SUBMIT_RESPONSE_FEEDBACK_RESET,
  SUBMIT_RESPONSE_FEEDBACK_SUCCESS,
} from '../constants/feedbackConstants';

export const getFeedbacksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FEEDBACKS_REQUEST:
      return { loadingGetFeedbacks: true };
    case GET_FEEDBACKS_SUCCESS:
      return {
        loadingGetFeedbacks: false,
        getFeedbacksData: action.payload,
      };
    case GET_FEEDBACKS_FAIL:
      return {
        loadingGetFeedbacks: false,
        errorGetFeedbacks: action.payload,
      };
    case GET_FEEDBACKS_RESET:
      return {};
    default:
      return state;
  }
};

export const submitFeedbackResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_RESPONSE_FEEDBACK_REQUEST:
      return { loadingSubmitFeedbackResponse: true };
    case SUBMIT_RESPONSE_FEEDBACK_SUCCESS:
      return {
        loadingSubmitFeedbackResponse: false,
        submitFeedbackResponseData: action.payload,
      };
    case SUBMIT_RESPONSE_FEEDBACK_FAIL:
      return {
        loadingSubmitFeedbackResponse: false,
        errorSubmitFeedbackResponse: action.payload,
      };
    case SUBMIT_RESPONSE_FEEDBACK_RESET:
      return {};
    default:
      return state;
  }
};
