import axios from 'axios';
import {
  ADD_BANNER_FAIL,
  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  GET_BANNERS_FAIL,
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  UPDATE_BANNER_FAIL,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
} from '../constants/bannerConstants';
import { BASE_URL } from '../constants/URL.js';

export const getBanners = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BANNERS_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/banners`, config);

    dispatch({
      type: GET_BANNERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BANNERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addBanner =
  (name, order, image, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_BANNER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/banners`,
        { name, order, image, type },
        config
      );

      dispatch({
        type: ADD_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateBanner =
  (id, order) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_BANNER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/banners/${id}`,
        { order },
        config
      );

      dispatch({
        type: UPDATE_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteBanner = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_BANNER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${BASE_URL}/banners/${id}`,
      {},
      config
    );

    dispatch({
      type: DELETE_BANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BANNER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
