import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Image } from 'cloudinary-react';

import Button from '../components/Button';
import Input from '../components/Input';
import Loading from '../components/Loading';
import MatTable from '../components/MatTable';
import {
  addBanner,
  deleteBanner,
  getBanners,
  updateBanner,
} from '../actions/bannerActions';
import {
  ADD_BANNER_RESET,
  DELETE_BANNER_RESET,
  GET_BANNERS_RESET,
  UPDATE_BANNER_RESET,
} from '../constants/bannerConstants';
import moment from 'moment';
import Select from 'react-select';
import { Edit, Delete } from '@material-ui/icons';

const Banner = ({ history }) => {
  // * States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addBannerModal, setAddBannerModal] = useState(false);
  const [name, setName] = useState('');
  const [order, setOrder] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [id, setId] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  // * Initialization
  const dispatch = useDispatch();

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/');
    }
  }, [userInfo, history]);

  useEffect(() => {
    setLoading(true);
    dispatch(getBanners());
  }, []);

  // * Get Banners
  const getBannersInfo = useSelector((state) => state.getBannersInfo);
  const { errorGetBanners, getBannersData } = getBannersInfo;

  useEffect(() => {
    dispatch({ type: GET_BANNERS_RESET });
    if (getBannersData) {
      setLoading(false);
      setData(getBannersData);
    } else if (errorGetBanners) {
      setLoading(false);
      toast(errorGetBanners, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [getBannersData, errorGetBanners]);

  const closeAddTodoModal = () => {
    setName('');
    setOrder('');
    setType('');
    setFile(null);
    setAddBannerModal(false);
  };

  const addBannerHandler = async () => {
    if (!name || !file || !order || !type) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    if (file.size > 2048 * 1000) {
      toast('File should not be greater than 2mb', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      dispatch(addBanner(name, order, reader.result, type.value));
    };
    reader.onerror = function (error) {
      toast(JSON.stringify(error), {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    };
  };

  const addBannerInfo = useSelector((state) => state.addBannerInfo);
  const { loadingAddBanner, errorAddBanner, addBannerData } =
    addBannerInfo;

  useEffect(() => {
    dispatch({ type: ADD_BANNER_RESET });
    if (addBannerData) {
      closeAddTodoModal();
      toast('Banner added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      setTimeout(() => {
        dispatch(getBanners());
      }, 500);
    } else if (errorAddBanner) {
      toast(errorAddBanner, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [addBannerData, errorAddBanner]);

  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'order',
      title: 'Order',
    },
    {
      field: 'image',
      title: 'Image',
      render: (rowData) => {
        return (
          <Image
            cloudName="emporio"
            publicId={rowData.image}
            key={rowData.image}
            width="280"
            height="180"
            crop="scale"
            className="m-4"
          />
        );
      },
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return (
          <p className="text-sm">
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        );
      },
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      render: (rowData) => {
        return (
          <p className="text-sm">
            {moment(rowData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        );
      },
    },
    {
      field: '_id',
      title: 'Actions',
      render: (rowData) => {
        return (
          <>
            {rowData._id ? (
              <>
                <div
                  className="cursor-pointer"
                  onClick={() => openUpdateBanner(rowData)}
                >
                  <Edit />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => openDeleteBanner(rowData)}
                >
                  <Delete />
                </div>
              </>
            ) : (
              'N/A'
            )}
          </>
        );
      },
    },
  ];

  const openUpdateBanner = (data) => {
    setId(data._id);
    setName(data.name);
    setOrder(data.order);
    setUpdateModal(true);
  };

  const closeUpdateBanner = () => {
    setId('');
    setName('');
    setOrder('');
    setUpdateModal(false);
  };

  const updateBannerHandler = async () => {
    if (!id || !order) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    dispatch(updateBanner(id, order));
  };

  const updateBannerInfo = useSelector(
    (state) => state.updateBannerInfo
  );
  const { loadingUpdateBanner, errorUpdateBanner, updateBannerData } =
    updateBannerInfo;

  useEffect(() => {
    dispatch({ type: UPDATE_BANNER_RESET });
    if (updateBannerData) {
      closeUpdateBanner();
      toast('Banner updated successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      setTimeout(() => {
        dispatch(getBanners());
      }, 500);
    } else if (errorUpdateBanner) {
      toast(errorUpdateBanner, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [updateBannerData, errorUpdateBanner]);

  const openDeleteBanner = (data) => {
    setId(data._id);
    setName(data.name);
    setDeleteModal(true);
  };

  const closeDeleteBanner = () => {
    setId('');
    setName('');
    setDeleteModal(false);
  };

  const deleteBannerHandler = () => {
    dispatch(deleteBanner(id));
  };

  const deleteBannerInfo = useSelector(
    (state) => state.deleteBannerInfo
  );
  const { loadingDeleteBanner, errorDeleteBanner, deleteBannerData } =
    deleteBannerInfo;

  useEffect(() => {
    dispatch({ type: DELETE_BANNER_RESET });
    if (deleteBannerData) {
      closeDeleteBanner();
      toast('Banner deleted successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      setTimeout(() => {
        dispatch(getBanners());
      }, 500);
    } else if (errorDeleteBanner) {
      toast(errorDeleteBanner, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [deleteBannerData, errorDeleteBanner]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-full h-full">
        <h1 className="text-2xl font-semibold">Banners</h1>
        <div className="bg-white shadow-md rounded px-8 py-4 my-4">
          <div className="flex justify-end">
            <Button
              custom="py-2"
              type="button"
              onClick={() => setAddBannerModal(true)}
              text="Add a Banner"
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type="Banners" />
        {addBannerModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">
                      Add Banner
                    </h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <Input
                      width="w-full mb-4"
                      name="Name *"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      width="w-full mb-4"
                      name="Order *"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      type="number"
                    />
                    <Select
                      options={[
                        { value: 'Banner', label: 'Banner' },
                        { value: 'Brand', label: 'Brand' },
                      ]}
                      value={type}
                      onChange={(e) => setType(e)}
                    />
                    <Input
                      width="w-full mt-4 mb-4"
                      name="Image *"
                      onChange={(e) => setFile(e.target.files[0])}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                    />
                    <p className="text-sm">
                      All the fields with * are mandatory
                    </p>
                  </div>
                  <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeAddTodoModal}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                      type="button"
                      onClick={addBannerHandler}
                      disabled={loadingAddBanner}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        {updateModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">{name}</h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <Input
                      width="w-full mb-4"
                      name="Order *"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      type="number"
                    />
                    <p className="text-sm">
                      Enter order which is not currently being used.
                    </p>
                  </div>
                  <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeUpdateBanner}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                      type="button"
                      onClick={updateBannerHandler}
                      disabled={loadingUpdateBanner}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        {deleteModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">{name}</h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <p className="text-sm">
                      Are you sure, you want to delete this banner?
                    </p>
                  </div>
                  <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeDeleteBanner}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300"
                      type="button"
                      onClick={deleteBannerHandler}
                      disabled={loadingDeleteBanner}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </div>
    </>
  );
};

export default Banner;
