import {
  ADD_BANNER_FAIL,
  ADD_BANNER_REQUEST,
  ADD_BANNER_RESET,
  ADD_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_RESET,
  DELETE_BANNER_SUCCESS,
  GET_BANNERS_FAIL,
  GET_BANNERS_REQUEST,
  GET_BANNERS_RESET,
  GET_BANNERS_SUCCESS,
  UPDATE_BANNER_FAIL,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_RESET,
  UPDATE_BANNER_SUCCESS,
} from '../constants/bannerConstants';

export const getBannersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BANNERS_REQUEST:
      return { loadingGetBanners: true };
    case GET_BANNERS_SUCCESS:
      return {
        loadingGetBanners: false,
        getBannersData: action.payload,
      };
    case GET_BANNERS_FAIL:
      return {
        loadingGetBanners: false,
        errorGetBanners: action.payload,
      };
    case GET_BANNERS_RESET:
      return {};
    default:
      return state;
  }
};

export const addBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BANNER_REQUEST:
      return { loadingAddBanner: true };
    case ADD_BANNER_SUCCESS:
      return {
        loadingAddBanner: false,
        addBannerData: action.payload,
      };
    case ADD_BANNER_FAIL:
      return {
        loadingAddBanner: false,
        errorAddBanner: action.payload,
      };
    case ADD_BANNER_RESET:
      return {};
    default:
      return state;
  }
};

export const updateBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BANNER_REQUEST:
      return { loadingUpdateBanner: true };
    case UPDATE_BANNER_SUCCESS:
      return {
        loadingUpdateBanner: false,
        updateBannerData: action.payload,
      };
    case UPDATE_BANNER_FAIL:
      return {
        loadingUpdateBanner: false,
        errorUpdateBanner: action.payload,
      };
    case UPDATE_BANNER_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BANNER_REQUEST:
      return { loadingDeleteBanner: true };
    case DELETE_BANNER_SUCCESS:
      return {
        loadingDeleteBanner: false,
        deleteBannerData: action.payload,
      };
    case DELETE_BANNER_FAIL:
      return {
        loadingDeleteBanner: false,
        errorDeleteBanner: action.payload,
      };
    case DELETE_BANNER_RESET:
      return {};
    default:
      return state;
  }
};
