import axios from 'axios'
import {
  ADD_QRCODE_FAIL,
  ADD_QRCODE_REQUEST,
  ADD_QRCODE_SUCCESS,
  GET_QRCODES_FAIL,
  GET_QRCODES_REQUEST,
  GET_QRCODES_SUCCESS,
  UPDATE_QRCODE_FAIL,
  UPDATE_QRCODE_REQUEST,
  UPDATE_QRCODE_SUCCESS,
} from '../constants/qrcodeConstants'
import { BASE_URL } from '../constants/URL.js'

export const getQrCodes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_QRCODES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${BASE_URL}/qrcodes`, config)

    dispatch({
      type: GET_QRCODES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_QRCODES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addQrCode = (name, questions) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_QRCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${BASE_URL}/qrcodes`,
      { name, questions },
      config
    )

    dispatch({
      type: ADD_QRCODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADD_QRCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateQrCode = (id, questions) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_QRCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${BASE_URL}/qrcodes/${id}`,
      { questions },
      config
    )

    dispatch({
      type: UPDATE_QRCODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_QRCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
