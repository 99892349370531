import axios from 'axios'
import {
  ADD_TODO_FAIL,
  ADD_TODO_REQUEST,
  ADD_TODO_SUCCESS,
  GET_TODOS_FAIL,
  GET_TODOS_REQUEST,
  GET_TODOS_SUCCESS,
} from '../constants/todoConstants'
import { BASE_URL } from '../constants/URL.js'

export const getTodos = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TODOS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${BASE_URL}/todos/all`, config)

    dispatch({
      type: GET_TODOS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_TODOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addTodo =
  (message, department, user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_TODO_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${BASE_URL}/todos`,
        { message, department, user },
        config
      )

      dispatch({
        type: ADD_TODO_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_TODO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
