import {
  ADD_QRCODE_FAIL,
  ADD_QRCODE_REQUEST,
  ADD_QRCODE_RESET,
  ADD_QRCODE_SUCCESS,
  GET_QRCODES_FAIL,
  GET_QRCODES_REQUEST,
  GET_QRCODES_RESET,
  GET_QRCODES_SUCCESS,
  UPDATE_QRCODE_FAIL,
  UPDATE_QRCODE_REQUEST,
  UPDATE_QRCODE_RESET,
  UPDATE_QRCODE_SUCCESS,
} from '../constants/qrcodeConstants'

export const getQrCodesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QRCODES_REQUEST:
      return { loadingGetQrCodes: true }
    case GET_QRCODES_SUCCESS:
      return { loadingGetQrCodes: false, getQrCodesData: action.payload }
    case GET_QRCODES_FAIL:
      return { loadingGetQrCodes: false, errorGetQrCodes: action.payload }
    case GET_QRCODES_RESET:
      return {}
    default:
      return state
  }
}

export const addQrCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_QRCODE_REQUEST:
      return { loadingAddQrCode: true }
    case ADD_QRCODE_SUCCESS:
      return { loadingAddQrCode: false, addQrCodeData: action.payload }
    case ADD_QRCODE_FAIL:
      return { loadingAddQrCode: false, errorAddQrCode: action.payload }
    case ADD_QRCODE_RESET:
      return {}
    default:
      return state
  }
}

export const updateQrCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_QRCODE_REQUEST:
      return { loadingUpdateQrCode: true }
    case UPDATE_QRCODE_SUCCESS:
      return { loadingUpdateQrCode: false, updateQrCodeData: action.payload }
    case UPDATE_QRCODE_FAIL:
      return { loadingUpdateQrCode: false, errorUpdateQrCode: action.payload }
    case UPDATE_QRCODE_RESET:
      return {}
    default:
      return state
  }
}
