import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import {
  userLoginReducer,
  addUserReducer,
  getUsersReducer,
  newPasswordUserReducer,
  updateUserReducer,
} from './reducers/userReducers';
import {
  deleteQueryReducer,
  getQueryReducer,
  updateQueryReducer,
} from './reducers/queryReducers';
import {
  addMessageReducer,
  deleteMessageReducer,
  getMessagesReducer,
  uploadMessageReducer,
} from './reducers/messageReducers';
import {
  addTodoReducer,
  getTodosReducer,
} from './reducers/todoReducers';
import {
  addBannerReducer,
  deleteBannerReducer,
  getBannersReducer,
  updateBannerReducer,
} from './reducers/bannerReducers';
import {
  getFeedbacksReducer,
  submitFeedbackResponseReducer,
} from './reducers/feedbackReducers';
import {
  getQrCodesReducer,
  addQrCodeReducer,
  updateQrCodeReducer,
} from './reducers/qrcodeReducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  getUsersInfo: getUsersReducer,
  addUserInfo: addUserReducer,
  updateUserInfo: updateUserReducer,
  newPasswordUserInfo: newPasswordUserReducer,
  getQueryInfo: getQueryReducer,
  getMessagesInfo: getMessagesReducer,
  addMessageInfo: addMessageReducer,
  deleteMessageInfo: deleteMessageReducer,
  deleteQueryInfo: deleteQueryReducer,
  updateQueryInfo: updateQueryReducer,
  getTodosInfo: getTodosReducer,
  addTodoInfo: addTodoReducer,
  uploadMessageInfo: uploadMessageReducer,
  getBannersInfo: getBannersReducer,
  addBannerInfo: addBannerReducer,
  updateBannerInfo: updateBannerReducer,
  getFeedbacksInfo: getFeedbacksReducer,
  getQrCodesInfo: getQrCodesReducer,
  addQrCodeInfo: addQrCodeReducer,
  updateQrCodeInfo: updateQrCodeReducer,
  submitFeedbackResponseInfo: submitFeedbackResponseReducer,
  deleteBannerInfo: deleteBannerReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const inititalState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
